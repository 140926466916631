import { createApp } from 'vue'
import router  from './router'
import App from './App.vue'
import "./main.css";


createApp(App).use(router).mount('#app')
// const BASE_URL = "https://lesmeilleursoffres.fr";
const BACK_URL = "https://meilleuresoffresenergies.fr/chatEnergieApi";

const config = {
    // BASE_URL,
    BACK_URL
}

export default config; 
